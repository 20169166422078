(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.editorSkinsData = f()}})(function(){var define,module,exports;
/* Autogenerated file. Do not modify */
'use strict';
var skins = {};
 skins['platform.components.skins.controllerIconAndNameSkin'] = {
  "react": [
    [
      "div",
      "inlineContent",
      [],
      {},
      [
        "img",
        "icon",
        [],
        {}
      ],
      [
        "span",
        "name",
        [],
        {}
      ]
    ]
  ],
  "css": {
    "%": "z-index:70;display:table;",
    "%inlineContent": "min-width:60px;max-width:96px;min-height:60px;max-height:96px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;",
    "%icon": "display:block;margin:6px auto;box-shadow:0px 2px 8px 0px rgba(0, 0, 0, 0.16), 0px -1px 1px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.4);border-radius:13px;width:48px;height:48px;",
    "%name": "display:inline-block;width:100%;padding:0 1px;box-sizing:border-box;text-overflow:ellipsis;text-align:center;overflow:hidden;line-height:18px;max-height:calc(2 * 18px);text-shadow:0px 2px 6px rgba(0, 0, 0, 0.7), 0px -1px 1px rgba(0, 0, 0, 0.5), 0px 1px 1px rgba(0, 0, 0, 0.88);color:#ffffff;font-family:HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;font-size:13px;"
  }
}
 skins['platform.components.skins.controllerSkin'] = {
  "react": [],
  "css": {
    "%": "display:none;"
  }
}

return skins;

});
